.main_container {
    margin-top: 24px;
    background: #F8F9FD;
    width: 100%;
    border: 6px solid #FFFFFF;
    border-radius: 12px;
    box-shadow: 12px 12px 48px 0px rgba(80, 80, 159, 0.04);
    padding: 15px 30px; 
}

.gridItem {
    border-radius:  12px ;
    background: #FFF;
    box-shadow:  12px 12px 48px 0px rgba(80, 80, 159, 0.04);
    height:  100% ;
    min-width:  25% ;
    padding: 20px;
}

.displayColCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.displayRowLeft {
    display: flex;
    align-items: center;
}

.displayRowCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.displayRowSpaced {
    width: 100%;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.displayRowEven {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.highlight1 {
    background: #FAFAFA;
    margin: 8px 0px;
    padding:  10px 20px ;
    border-radius:  6px ;
    width:  100% ;
}

.hightlight2 {
    background: #F8F9FD;
    width: 100%;
    border-radius: 12px;
    padding: 0px 15px;
}

.highlight3 {
    border-radius: 6px;
    background: #f7f7f7;
    border: 1px solid #EAEAEC;
}

.h22 {
    font-size: 22px !important;
    font-weight: 600;
    margin: auto 0px;
}

.h18 {
    font-size: 18px;
    font-weight: 700;
    color: #2E3044;
}

.h16 {
    font-size: 16px;
    font-weight: 600;
    color: #2E3044;
}

.h14 {
    font-size: 14px !important;
    font-weight: 500 !important;
    color:#585969;
}

.h14_light {
    font-size: 14px !important;
    font-weight: 600;
    color:#C0C1C7;
}

.h14_dark {
    font-family: 'Inter';
    font-size: 14px !important;
    font-weight: 500;
    color:#333;
    margin-bottom: 0;
}

.h12 {
    font-size: 12px !important;
    font-weight: 500;
    color:#333;
}

.pointer {
    cursor: pointer; 
}

.join-icon {
    width:  35px  ; 
    height:   35px  ;
    padding-left:  10px  ;
    padding-top:  10px  ; 
    background:  #FFF ;
    border: 1px solid #E5E5E5;
}

.join-icon > svg {
    cursor: pointer;
    width: 18px;
    height: 18px;
}